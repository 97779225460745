import React from "react";
import "../project.css";
import { NavLink } from "react-router-dom";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";


import img1 from "./pilolo1.png";
import img3 from "./pilolo3.png";
import img2 from "./pilolo2.png";
import img4 from "./pilolo4.png";

import img6 from "./pilolo6.png";
import img7 from "./pilolo7.png";
import img10 from "./pilolo10.png";
import img12 from "./pilolo12.png";

import img8 from "./pilolo8.png";
import img5 from "./pilolo5.png";
import img9 from "./pilolo9.png";
import img11 from "./pilolo11.png";

import img15 from "./pilolo15.png";
import img16 from "./pilolo16.png";

import '@splidejs/splide/dist/css/splide.min.css';
import { Splide, SplideSlide } from '@splidejs/react-splide';

const Pilolo = () => {
  return (
    <div className="overall project-overall">
      <div className="text">
        <div className="title">
          <h1>PILolo</h1>
          <div className="description">
            <h2>Brand identity design for A kids’ clothing brand with a difference.</h2>
            <p>Branding + Visual Strategy + illustration + packaging</p>
          </div>
        </div>
        <div className="image">
          <img src={img1} alt="design" />
        </div>
        <div className="right-text">
          <p>2022-2023</p>
          <p>
            Imagine looking at the world through a child’s eyes - you’d see
            boundless energy, play, curiosity and bright colours! <br></br>
            <br></br>
            Bangalore-based fashion startup, Meraki Clothing Pvt. Ltd. saw an opportunity to build a kids fashion brand with a modern look, that prioritises childlike wonder, play, and comfort.{" "}
          </p>
        </div>

        <div className="image2">
          <img src={img3} alt="design" />
        </div>

        <div className="right-text">
          <p>
            Pilolo was created to bring comfortable and innovative kids’ clothes
            to the market. Pilolo did this by championing pockets in every
            garment, dresses that grow with you, and using a fitting system that
            is gender neutral. They needed a brand identity that was friendly,
            modern, and engaging. {" "}
          </p>
        </div>

        <div className="image2">
          <img src={img2} alt="design" />
        </div>

        <div className="right-text">
          <p>
            The soft geometry of the Pilolo wordmark comes from the phonetic roundedness of the name. The logo contains a pair of ‘Lolo Eyes’. They are curious and friendly, and are used across Pilolo’s branding as a symbol, the eyes of a mascot, and as a graphic element on merchandise.
          </p>
        </div>


        <div className="image2">
          <img src={img4} alt="design" />
        </div>

        <div className="right-text">
          <p>
            As an in-house brand identity designer, I had the opportunity to
            work on a wide array of brand touchpoints, including, campaign
            shoots, packaging, merchandise, <a className="link" href="https://www.instagram.com/hellopilolo/" target="_blank">social media</a>, and the website. I had
            the opportunity to oversee the development of brand assets, a
            flatlay garment shoot, a studio shoot, and even coordinate the
            development of the <a className="link" href="http://www.pilolo.shop" target="_blank">website</a>.
          </p>
        </div>

        <div className="project-2-cols square-image">
          <div className="left">
            <Splide
              options={{
                gap: '1rem',
                pagination: false,
                arrows: false,
                width: '100%',
                type: 'loop',
                speed: 200,
                autoplay: true,
                interval: 2000,
                pauseOnHover: false,
                pauseOnFocus: false,
              }}
              aria-label="My Favorite Images"
            >
              <SplideSlide>
                <img src={img6} alt="img" loading="eager" />
              </SplideSlide>
              <SplideSlide>
                <img src={img7} alt="img" loading="eager" />
              </SplideSlide>
            </Splide>
          </div>
          <div className="right">
            <Splide
              options={{
                gap: '1rem',
                pagination: false,
                arrows: false,
                width: '100%',
                type: 'loop',
                speed: 200,
                autoplay: true,
                interval: 2000,
                pauseOnHover: false,
                pauseOnFocus: false,
              }}
              aria-label="My Favorite Images"
            >
              <SplideSlide>
                <img src={img10} alt="img" loading="eager" />
              </SplideSlide>
              <SplideSlide>
                <img src={img12} alt="img" loading="eager" />
              </SplideSlide>
            </Splide>
          </div>
        </div>

        <div className="text">
          <div className="left-text"></div>
          <div className="right-text">
            <p>
              The most unique feature of any Pilolo garment has to be the hidden pocket. Regardless of gender, each garment from Pilolo comes with a hidden pocket, containing a little surprise inside.
            </p>
          </div>
        </div>

        <div className="project-2-cols square-image">
          <div className="left">
            <Splide
              options={{
                gap: '1rem',
                pagination: false,
                arrows: false,
                width: '100%',
                type: 'loop',
                speed: 200,
                autoplay: true,
                interval: 2000,
                pauseOnHover: false,
                pauseOnFocus: false,
              }}
              aria-label="My Favorite Images"
            >
              <SplideSlide>
                <img src={img8} alt="img" loading="eager" />
              </SplideSlide>
              <SplideSlide>
                <img src={img5} alt="img" loading="eager" />
              </SplideSlide>
            </Splide>
          </div>
          <div className="right">
            <Splide
              options={{
                gap: '1rem',
                pagination: false,
                arrows: false,
                width: '100%',
                type: 'loop',
                speed: 200,
                autoplay: true,
                interval: 2000,
                pauseOnHover: false,
                pauseOnFocus: false,
              }}
              aria-label="My Favorite Images"
            >
              <SplideSlide>
                <img src={img9} alt="img" loading="eager" />
              </SplideSlide>
              <SplideSlide>
                <img src={img11} alt="img" loading="eager" />
              </SplideSlide>
            </Splide>
          </div>
        </div>
        <div className="right-text">
          <p>
            The brand was launched in the United Arab Emirates, with a pop-up store in a popular theme park. It was incredibly thrilling to see something I worked on over the course of many months come to life, and I’m really excited to see where the brand goes next.
          </p>
        </div>
        <div className="project-2-cols size-4-3">
          <div className="left">
            <img src={img15} alt="img" loading="eager" />
          </div>
          <div className="right">
            <img src={img16} alt="img" loading="eager" />
          </div>
        </div>

        <div className="right-text">
          <p>
            Team @ Meraki Clothing<br />
            Core: Raahul Prem Sikka, Meenal Jethi, Ramki D.<br />
            Fashion/Garment Design: Meenal Jethi, Geetika Yeshwantrao, Vinothkumar C.<br />
            Brand Design & Art: Shriya Sista<br />
            Marketing and Social Media: Sakshi Chanana & Khushi Lunawat<br />
            Operations and Recruiting: Raveena Merin<br /><br />

            Team @ Shot By Labony<br />
            Executive Producer: Labony Kaushal<br />
            Director: Karan Agarwal<br />
            Theme Photographer: Natasha Hemrajani<br />
            Catalogue Photographer: Vidhi Dwivedi<br />
            DOP: Sumedh Patil<br />
            Assistant DoP: Rupa Patil<br />
            Associate Producer: Ravi Ochani<br />
            Line Producer: Rahul Shukla<br />
            Lights: Sanjay<br />
            Costume Stylist: Priya Premnath Singh<br />
            Make-Up & Hair Stylist: Prashant Kamble<br />
            Casting Director: Janet<br />
            Offline Editor: Aman Agarwal<br />
            Online Editor: Aman Agarwal<br />
            Music Director: Hrishi Giridhar
          </p>
        </div>

      </div>





      <div className="navigation">
        <NavLink to="/wlbs">
          <FaArrowLeft />
        </NavLink>
        <NavLink to="/foundry_berlin">
          <FaArrowRight />
        </NavLink>
      </div>
    </div>
  );
};

export default Pilolo;
