import React from "react";
import "../../project.css"
import image from "./image1.png";
import { useEffect } from "react";
import { NavLink , useLocation } from "react-router-dom";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";


const Pitbull = () => {
  const location = useLocation();
  useEffect(() => {
    // import { useEffect } from "react";
    // import { NavLink , useLocation } from "react-router-dom";
    const head = document.querySelector('header');
    const footer = document.querySelector('footer');
    if ((location.pathname.includes('/blog'))) {
        document.body.style.color = 'white';
        document.body.style.backgroundColor = 'black';
        head.style.color = "white";
        head.style.backgroundColor = "transparent"
        footer.style.color = "white";
        footer.style.backgroundColor = "transparent"
    } else {
        // Reset to default styles if not on the /about route
        document.body.style.color = 'black';
        document.body.style.backgroundColor = 'white';
        head.style.color = "black";
        head.style.backgroundColor = "white"
        footer.style.color = "black";
        footer.style.backgroundColor = "white"
    }
}, [location.pathname]);
  return (
    <div className="overall project-overall blog">
      <div className="blog text">
        <div className="title">
          <h1>Pitbull as an absurd hero</h1>
          <div className="description">
            <h2>A short exploration of the work of Armando Christian Perez, a true Renaissance man.</h2>
            <p>2023</p>
          </div>
        </div>
        <div className="image">
          <img src={image} alt="design" />
        </div>
        <div className="right-text">
        <p>
          "History’s greatest men - celebrated writers, scholars, artists, and
          explorers - are all remembered for having met the challenges that
          fortune afforded them with grit, creativity, and an unusual knack for
          their medium. We regard them as heroes, build institutions to protect
          their legacies, or spend entire careers hoping to scribble down an
          interpretation of their genius to frame as our own. This short essay
          is but a similar attempt, the feeble endeavour of an amateur, to
          explore the life and times of a true contemporary renaissance man,
          Armando Christian Pérez."
          <br /> <br />       
          "To a casual listener, the themes of his poetry appear lacking in
          intellectual substance and true philosophical insight. His words
          invoke visions of high-spirited merry-making, whirlwind love affairs,
          and material pleasures known only to men of great power. It is easy
          for listeners to dismiss his work as shallow, when, in fact, a closer
          look at any poem often reveals a nuanced understanding of society,
          politics, and culture, delivered in the poetic voice of a flamboyant,
          charismatic, and multi-lingual protagonist"
          <br /> <br />       
          Pérez’s on-stage persona, Pitbull, can be interpreted to represent a
          sort of absurd hero. He is not bound by conventional morality,
          relentlessly seeks success and is in the constant pursuit of pleasure.
          This pursuit of hedonistic pleasure can be interpreted as a sort of
          defiant response to the drudgery, futility and daily struggles of
          human life.{" "}
          <br /> <br />       
          Me not workin' hard? Yeah right<br></br>
          Picture that with a Kodak<br></br>
          Or, better yet, go to Times Square <br></br>
          Take a picture of me with a Kodak<br></br>
          Took my life from negative to positive<br></br>I just want y'all to
          know that<br></br>
          And tonight, let's enjoy life<br></br>
          Pitbull, Nayer, Ne-Yo, tell us right
          <br /> <br />       
          There’s a sort of Sisyphean element to the lifestyle of Pitbull as
          described in his highest charting song ever, ‘Give Me Everything
          (Tonight)’. The lyrics “Me not workin’ hard? Yeah right picture that
          with a Kodak”, suggest that Pitbull cannot escape the rigmarole of
          daily life, and invites - or even challenges - the listener to catch
          him slacking or giving up the fight in the face of eternal struggle.
          Instead, the listener is likelier to find him triumphant in his
          pursuit of commercial success, on the face of a large building in
          Times Square. He then goes on to tell the listeners, in a moment of
          brotherly reassurance and in the form of a clever photography pun,
          that he “Took his life from negative to positive”, and he just wants
          us to know that. In the vein of Albert Camus, Pitbulls words suggest
          that one must imagine Sisyphus happy.{" "}
          <br /> <br />       
          However, is Pitbull the true heir to the great absurdist philosophers
          of the past? He does, like many greats of the genre, embrace
          individualism, a non-traditional plot structure, and acknowledges that
          despite the struggles, achievements and suffering, life amounts to
          nothing but an insignificant passage of time, so one might as well
          pursue pleasure. In his 2014 hit Fireball, Perez reflects on his
          resilience in the face of violence, declaring his superiority over his
          assailants, and establishing to the listener, that unlike the ‘boys’
          he’s antagonised by, this ‘Chico right here’ doesn’t take things
          lightly.
          <br /> <br />       
          Sticks and stones may break my bones<br></br>
          But I don't care what y'all say<br></br>
          'Cause as the world turns, y'all boys gonna learn<br></br>
          That Chico right here don't play<br></br>
          <br /> <br />          
          In essence, Pitbull's work stands as a testament to the human spirit's
          resilience and adaptability in the face of the absurdity of life. His
          music, a mix of party anthems and philosophical musings, challenges us
          to reflect on the fleeting nature of existence while finding joy and
          purpose in the here and now.
        </p>
        </div>
      </div>
      <div className="navigation">
        <NavLink to="/blog/miscellaneous-comics">
          <FaArrowLeft />
        </NavLink>
        <NavLink to="/blog/nine36">
          <FaArrowRight />
        </NavLink>
      </div>
    </div>
  );
};

export default Pitbull;
