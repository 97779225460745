import React from "react";
import "../project.css";
import { NavLink } from "react-router-dom";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import '@splidejs/splide/dist/css/splide.min.css';
import slider1_img1 from './Slider1_1.jpg';
import slider1_img2 from './Slider1_2.jpg';
import slider1_img3 from './Slider1_3.jpg';
import slider1_img4 from './Slider1_4.jpg';
import slider1_img5 from './Slider1_5.jpg';
import slider1_img6 from './Slider1_6.jpg';
import slider1_img7 from './Slider1_7.jpg';
import slider2_img1 from './Slider2_1.jpg';
import slider2_img2 from './Slider2_2.jpg';
import slider2_img3 from './Slider2_3.jpg';
import slider2_img4 from './Slider2_4.jpg';
import slider2_img5 from './Slider2_5.jpg';
import slider2_img6 from './Slider2_6.jpg';
import slider2_img7 from './Slider2_7.jpg';
import slider3_img1 from './Slider3_1.jpg';
import slider3_img2 from './Slider3_2.jpg';
import slider3_img3 from './Slider3_3.jpg';
import slider3_img4 from './Slider3_4.jpg';
import slider3_img5 from './Slider3_5.jpg';
import slider3_img6 from './Slider3_6.jpg';
import slider3_img7 from './Slider3_7.jpg';
import slider4_img1 from './Slider4_1.jpg';
import slider4_img2 from './Slider4_2.jpg';
import slider4_img3 from './Slider4_3.jpg';
import slider4_img4 from './Slider4_4.jpg';
import slider4_img5 from './Slider4_5.jpg';
import slider4_img6 from './Slider4_6.jpg';
import slider4_img7 from './Slider4_7.jpg';
import slider5_img1 from './slider5_1.png';
import slider5_img2 from './slider5_2.png';
import slider5_img3 from './slider5_3.png';
import slider5_img4 from './slider5_4.png';
import slider5_img5 from './slider5_5.png';
import slider5_img6 from './slider5_6.png';
import slider5_img7 from './slider5_7.png';
import slider5_img8 from './slider5_8.png';

import { Splide, SplideSlide } from '@splidejs/react-splide';

const Ivest = () => {
  return (
    <div className="overall project-overall">
      <div className="text">
        <div className="title">
          <h1>Alpian + I-VEST @ Foundry, Berlin</h1>
          <div className="description">
            <h2>Illustrations & data visuals for Switzerland’s first digital bank.</h2>
            <p>ILLUSTRATION + SOCIAL MEDIA + DATA VISUALISATION</p>
          </div>
        </div>
        <div className="right-text">
          <p>2022</p>
          <p>
            During my internship at Foundry, Berlin, I had the opportunity to work on a diverse array of projects, ranging from brand identity design, illustration, and user interface design to video editing and motion graphics. The tasks were hands-on, engaging, and part of live projects guided closely by experts. <br></br><br></br>
          </p>
        </div>
      </div>



      <div className="project-2-cols">
        <div className="left">
          <Splide
            options={{
              gap: '1rem',
              pagination: false,
              arrows: false,
              width: '100%',
              type: 'loop',
              speed: 200,
              autoplay: true,
              interval: 2000,
              pauseOnHover: false,
              pauseOnFocus: false,
            }}
            aria-label="My Favorite Images"
          >
            <SplideSlide>
              <img src={slider1_img1} alt="img" loading="eager" />
            </SplideSlide>
            <SplideSlide>
              <img src={slider1_img2} alt="img" loading="eager" />
            </SplideSlide>
            <SplideSlide>
              <img src={slider1_img3} alt="img" loading="eager" />
            </SplideSlide>
            <SplideSlide>
              <img src={slider1_img4} alt="img" loading="eager" />
            </SplideSlide>
            <SplideSlide>
              <img src={slider1_img5} alt="img" loading="eager" />
            </SplideSlide>
            <SplideSlide>
              <img src={slider1_img6} alt="img" loading="eager" />
            </SplideSlide>
            <SplideSlide>
              <img src={slider1_img7} alt="img" loading="eager" />
            </SplideSlide>
          </Splide>
        </div>
        <div className="right">
          <Splide
            options={{
              gap: '1rem',
              pagination: false,
              arrows: false,
              width: '100%',
              type: 'loop',
              speed: 200,
              autoplay: true,
              interval: 2000,
              pauseOnHover: false,
              pauseOnFocus: false,
            }}
            aria-label="My Favorite Images"
          >
            <SplideSlide>
              <img src={slider2_img1} alt="img" loading="eager" />
            </SplideSlide>
            <SplideSlide>
              <img src={slider2_img2} alt="img" loading="eager" />
            </SplideSlide>
            <SplideSlide>
              <img src={slider2_img3} alt="img" loading="eager" />
            </SplideSlide>
            <SplideSlide>
              <img src={slider2_img4} alt="img" loading="eager" />
            </SplideSlide>
            <SplideSlide>
              <img src={slider2_img5} alt="img" loading="eager" />
            </SplideSlide>
            <SplideSlide>
              <img src={slider2_img6} alt="img" loading="eager" />
            </SplideSlide>
            <SplideSlide>
              <img src={slider2_img7} alt="img" loading="eager" />
            </SplideSlide>
          </Splide>
        </div>
      </div>

      <div className="text">
        <div className="right-text full-width">
          <p>
            Features of the illustrations were aligned to the i-vest brand visual language. For each carousel post, clean lines, friendly shapes and colours, and visual continuity were prioritised. The brand identity for i-vest and Alpian were developed with guidelines for illustration use and social media. I was part of a team of motion graphics experts, copywriters, and social media managers who informed the process of building a carousel. <br></br><br></br>
          </p>
        </div>
      </div>

      <div className="project-2-cols">
        <div className="left">
          <Splide
            options={{
              gap: '1rem',
              pagination: false,
              arrows: false,
              width: '100%',
              type: 'loop',
              speed: 200,
              autoplay: true,
              interval: 2000,
              pauseOnHover: false,
              pauseOnFocus: false,
            }}
            aria-label="My Favorite Images"
          >
            <SplideSlide>
              <img src={slider3_img1} alt="img" loading="eager" />
            </SplideSlide>
            <SplideSlide>
              <img src={slider3_img2} alt="img" loading="eager" />
            </SplideSlide>
            <SplideSlide>
              <img src={slider3_img3} alt="img" loading="eager" />
            </SplideSlide>
            <SplideSlide>
              <img src={slider3_img4} alt="img" loading="eager" />
            </SplideSlide>
            <SplideSlide>
              <img src={slider3_img5} alt="img" loading="eager" />
            </SplideSlide>
            <SplideSlide>
              <img src={slider3_img6} alt="img" loading="eager" />
            </SplideSlide>
            <SplideSlide>
              <img src={slider3_img7} alt="img" loading="eager" />
            </SplideSlide>
          </Splide>
        </div>
        <div className="right">
          <Splide
            options={{
              gap: '1rem',
              pagination: false,
              arrows: false,
              width: '100%',
              type: 'loop',
              speed: 200,
              autoplay: true,
              interval: 2000,
              pauseOnHover: false,
              pauseOnFocus: false,
            }}
            aria-label="My Favorite Images"
          >
            <SplideSlide>
              <img src={slider4_img1} alt="img" loading="eager" />
            </SplideSlide>
            <SplideSlide>
              <img src={slider4_img2} alt="img" loading="eager" />
            </SplideSlide>
            <SplideSlide>
              <img src={slider4_img3} alt="img" loading="eager" />
            </SplideSlide>
            <SplideSlide>
              <img src={slider4_img4} alt="img" loading="eager" />
            </SplideSlide>
            <SplideSlide>
              <img src={slider4_img5} alt="img" loading="eager" />
            </SplideSlide>
            <SplideSlide>
              <img src={slider4_img6} alt="img" loading="eager" />
            </SplideSlide>
            <SplideSlide>
              <img src={slider4_img7} alt="img" loading="eager" />
            </SplideSlide>
          </Splide>
        </div>
      </div>

      <div className="text">
        <div className="right-text full-width">
          <p>
            Apart from working on carousels, I also had the opportunity to work on adapting data and charts to the i-vest identity and colour schemes. The charts feature in articles about finance and sustainability on the i-vest blog by Alpian. View more <a href="https://www.i-vest.ch/education/impact-investing/systematizing-impact-contributors" className="link" target="_blank">here</a> . 
          </p>
        </div>
      </div>

      <br />

      <div className="image">
        <Splide
          options={{
            gap: '1rem',
            pagination: false,
            arrows: false,
            width: '100%',
            type: 'loop',
            speed: 200,
            autoplay: true,
            interval: 2000,
            pauseOnHover: false,
            pauseOnFocus: false,
          }}
          aria-label="My Favorite Images"
        >
          <SplideSlide>
            <img src={slider5_img1} alt="img" loading="eager" />
          </SplideSlide>
          <SplideSlide>
            <img src={slider5_img2} alt="img" loading="eager" />
          </SplideSlide>
          <SplideSlide>
            <img src={slider5_img3} alt="img" loading="eager" />
          </SplideSlide>
          <SplideSlide>
            <img src={slider5_img4} alt="img" loading="eager" />
          </SplideSlide>
          <SplideSlide>
            <img src={slider5_img5} alt="img" loading="eager" />
          </SplideSlide>
          <SplideSlide>
            <img src={slider5_img6} alt="img" loading="eager" />
          </SplideSlide>
          <SplideSlide>
            <img src={slider5_img7} alt="img" loading="eager" />
          </SplideSlide>
          <SplideSlide>
            <img src={slider5_img8} alt="img" loading="eager" />
          </SplideSlide>
        </Splide>
      </div>
      <div className="right-text">
        <p>
          Team:<br />
          Creative Director (Art): Eugenia Kubas<br />
          Art Director: Andrea Egidi<br />
          Senior Designer/Illustrator/Motion: Arseniy Sahalchyc<br />
          Creative Directors (Copy & Strategy): Nitin George, Lilli Kahana<br />
          Social Media Managers: Victoria Marmillod, Angie Lora<br />
          Account Director: Susann Kompa
        </p>

      </div>
      <div className="navigation">
        <NavLink to="/foundry_berlin">
          <FaArrowLeft />
        </NavLink>
        <NavLink to="/meraki_clothing">
          <FaArrowRight />
        </NavLink>
      </div>
    </div>
  );
};

export default Ivest;
