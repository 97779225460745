import React from "react";
import "../project.css";
import nft_1 from './nft_1.png';
import nft_2 from './nft_2.png';
import nft_3 from './nft_3.png';
import nft_4 from './nft_4.png';
import nft_5 from './nft_5.png';
import nft_6 from './nft_6.png';
import { NavLink } from "react-router-dom";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import { DragControls } from "framer-motion";

const DigitalE = () => {
  return (
    <div className="overall project-overall">
      <div className="text">
        <div className="title">
          <h1>Digital Elves</h1>
          <div className="description">
            <h2>nft illustrations I made when I had way too much time on my hands.</h2>
            <p>NFT art project</p>
          </div>
        </div>
        <div className="image">
          <img src={nft_4} alt="img" loading="eager" />
        </div>
        <div className="right-text">
          <p>
            The Cryptoart community on Twitter burst into action in the second half of 2021. Their discussions about the future of art, albeit divisive, were very compelling. I decided to explore this new and interesting space myself, and chose to work on a four-image NFT series.
            <br></br><br></br>

            The goal of this project was to learn as much as possible about the crypto/art space, while trying to explore a style of illustration I hadn't before. I learnt a great deal about how the blockchain works, how communities are built, and of course the jargon that is usually so daunting to newcomers like myself.<br></br><br></br>
            I chose to work on a profile picture-based project, and create four unique digital elves. My process involved referring to fantasy novels I enjoyed in my childhood, and drawing inspiration from visual styles that rose from increased accessibility to digital art media, to give them unique identities. <br></br><br></br>
            Check out the full project <a className="link" href="https://rarible.com/shriyasista/sale" target="_blank" > here </a>. Available to mint.

          </p>
          <br />
        </div>
      </div>

      <Splide
        options={{
          gap: '1rem',
          pagination: false,
          arrows: false,
          width: '100%',
          type: 'loop',
          speed: 200,
          autoplay: true,
          interval: 2500,
          drag : true
        }}
        aria-label="My Favorite Images"
      >
        <SplideSlide>
          <div className="image3">
            <img src={nft_2} alt="img" loading="eager" />
          </div>
        </SplideSlide>
        <SplideSlide>
          <div className="image3">
            <img src={nft_1} alt="img" loading="eager" />
          </div>
        </SplideSlide>
        <SplideSlide>
          <div className="image3">
            <img src={nft_3} alt="img" loading="eager" />
          </div>
        </SplideSlide>
        <SplideSlide>
          <div className="image3">
            <img src={nft_5} alt="img" loading="eager" />
          </div>
        </SplideSlide>
        <SplideSlide>
          <div className="image3">
            <img src={nft_6} alt="img" loading="eager" />
          </div>
        </SplideSlide>
      </Splide>


      <div className="navigation">
        <NavLink to="/brewbot_craft_brewery">
          <FaArrowLeft />
        </NavLink>
        <NavLink to="/maaher">
          <FaArrowRight />
        </NavLink>
      </div>
    </div>
  );
};

export default DigitalE;
