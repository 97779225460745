import React from 'react';
import './project2.css';
import image from './image1.png'
import image2 from './image2.png'
import image3 from './image3.png'
import image4 from './diwali_some.jpeg';
import image5 from './gaps_some.jpeg';
import image6 from './sizing_some.jpeg';
import { NavLink } from "react-router-dom";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";

const project2 = () => {
  return (
    <div className='overall project-overall'>
      <div className="text">
        <div className="title">
          <h1>Meraki  Clothing</h1>
          <div className="description">
            <h2>Corporate brand identity design for a bangalore-based fashion start-up.
            </h2>
            <p>Branding + Illustration + Social Media</p>
          </div>
        </div>
        <div className="image">
          <img src={image} alt="design" />
        </div>
        <div className='right-text'>
          <p>Meraki Clothing Pvt. Ltd. was looking for a corporate identity that was energetic and vibrant, while maintaining formality in official communications without adhering to corporate identity clichés.</p>
        </div>
      </div>
      <div className="image2">
        <img src={image2} alt="design" />
      </div>
      <div className="text">
        <div className='left-text'>
        </div>
        <div className='right-text'>
          <p>The loopy line is a constant feature of the visual language, and adds gestural spontaneity and lightness to the logo and any additional branded material. The typefaces used are Source Sans Variable, and Secular One Regular.</p>
          <p>Illustrations were developed to match the visual language, for use on social media. <br /> <br />Guided by Ramki D.</p> <br/>
        </div>
      </div>
      <div className="project-2-cols square-image">
        <img src={image5} alt="design" />
        <img src={image6} alt="design" />
      </div>
      <br/>
      <div className="image3">
        <img src={image3} alt="design" />
      </div>
      <div className="navigation">
        <NavLink to="/i-vest_by_alpian">
          <FaArrowLeft />
        </NavLink>
        <NavLink to="/nearly_200_days">
          <FaArrowRight />
        </NavLink>
      </div>
    </div>
  );
};

export default project2;
