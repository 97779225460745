import { NavLink } from 'react-router-dom';
import './Header.css'
const Header = () => {
  return (
    <header>
      <div className="title">
        <NavLink to="/">
          <h1>Shriya Sista </h1>
        </NavLink>
      </div>
    </header>
  )
}

export default Header;