import React from "react";
import "../project.css";
import { NavLink } from "react-router-dom";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import img1 from './assets/img-1.png'
import img2 from './assets/img-2.png'
import img3 from './assets/img-3.png'
import img4 from './assets/img-4.png'
import img5 from './assets/img-5.png'
import img6 from './assets/img-6.png'
import img7 from './assets/img-7.png'
import img8 from './assets/img-8.png'
import img9 from './assets/img-9.png'


import '@splidejs/splide/dist/css/splide.min.css';
import { Splide, SplideSlide } from '@splidejs/react-splide';

const Maaher = () => {
  return (
    <div className="overall project-overall">
      <div className="text">
        <div className="title">
          <h1>Maaher</h1>
          <div className="description">
            <h2>Identity Design for a fashion start-up celebrating maharashtrian culture.</h2>
            <p>Brand identity + Visual Strategy</p>
          </div>
        </div>
        <div className="image-large">
          <img src={img1} alt="design" />
        </div>
        <div className="right-text">
          <p>Maaher is a luxury fashion brand that intertwines the rich cultural heritage of Maharashtra with the modern sensibilities of a cosmopolitan audience. The brand's core offering—traditionally woven Paithani Sarees—serves as a canvas for storytelling, connecting its customers to their roots while celebrating the art of dressing up.
            <br /> <br />
            Maaher’s brand identity is meticulously crafted around the Caregiver and Ruler archetypes, with the brand itself embodying "The Matriarch." This duality allows Maaher to stand as both a custodian of Maharashtrian culture and a nurturing guide for its customers.
          </p>
        </div>
        <div className="right">
          <Splide
            options={{
              gap: '1rem',
              pagination: false,
              arrows: false,
              width: '100%',
              type: 'loop',
              speed: 500,
              autoplay: true,
              interval: 3000,
              pauseOnHover: false,
              pauseOnFocus: false,
            }}
            aria-label="My Favorite Images"
          >
            <SplideSlide className="image-large">
              <img src={img2} alt="img" loading="eager" />
            </SplideSlide>
            <SplideSlide className="image-large">
              <img src={img3} alt="img" loading="eager" />
            </SplideSlide>
          </Splide>
        </div>
        <div className="right-text">
          <p>
          Based on the vernacular architecture styles of Maharashtra’s heartland, the logo depicts an archway into a home, represented in perspective to form the outline of an uppercase ‘M’. The symbol links the brand directly to its Maharashtrian roots, while tying in the nostalgia of a traditional home. The typography leans into a traditional style, using a modified latin serif and devnagari font for the logotype. 
          </p>
        </div>
        <div className="image-large">
          <img src={img4} alt="design" />
        </div>
        <div className="image-large">
          <img src={img5} alt="design" />
        </div>
        <div className="right-text">
          <p>
          In its communication, Maaher is empathetic and personal, speaking directly to customers' emotions and cultural pride. The brand also adopts an insightful and educational tone, sharing knowledge about Maharashtrian culture and craftsmanship, further enriching the customer experience. The fonts chosen are Lora and Rubik, a pairing that optimises legibility and identity expression for both its digital and physical touchpoints. 
          </p>
        </div>
        <div className="right">
          <Splide
            options={{
              gap: '1rem',
              pagination: false,
              arrows: false,
              width: '100%',
              type: 'loop',
              speed: 500,
              autoplay: true,
              interval: 3000,
              pauseOnHover: false,
              pauseOnFocus: false,
            }}
            aria-label="My Favorite Images"
          >
            <SplideSlide className="image-large">
              <img src={img6} alt="img" loading="eager" />
            </SplideSlide>
            <SplideSlide className="image-large">
              <img src={img7} alt="img" loading="eager" />
            </SplideSlide>
          </Splide>
        </div>
        <div className="right-text">
          <p>
          The Maaher color palette is rich, plush, and earthy, providing a striking contrast to the vibrant hues of Paithani sarees. The primary colour is a deep, earthy brown, supported by a warm neutral tone, and three accent colours blue, green, and pink. The colour palette extends to Maaher’s packaging and social media. 
          </p>
        </div>

        <div className="right">
          <Splide
            options={{
              gap: '1rem',
              pagination: false,
              arrows: false,
              width: '100%',
              type: 'loop',
              speed: 500,
              autoplay: true,
              interval: 3000,
              pauseOnHover: false,
              pauseOnFocus: false,
            }}
            aria-label="My Favorite Images"
          >
            <SplideSlide className="image-large">
              <img src={img8} alt="img" loading="eager" />
            </SplideSlide>
            <SplideSlide className="image-large">
              <img src={img9} alt="img" loading="eager" />
            </SplideSlide>
          </Splide>
        </div>
        <div className="right-text">
          <p>
            Through this carefully curated identity, Maaher not only celebrates Maharashtrian heritage by making it relevant and accessible to a global audience, establishing itself as a brand that honors the past while embracing the future.
          </p>
        </div>

      </div>
      <div className="navigation">
        <NavLink to="/digital_elves">
          <FaArrowLeft />
        </NavLink>
        <NavLink to="/wlbs">
          <FaArrowRight />
        </NavLink>
      </div>
    </div>
  );
};

export default Maaher;
