import React from "react";
import "../project.css";
import img1 from './brewbot_1.png'
import img2 from './brewbot_2.png'
import img3 from './brewbot_3.png'
import img4 from './brewbot_4.png'
// import img5 from './brewbot_5.png'
import img6 from './brewbot_6.png'
import img7 from './brewbot_7.png'
import img8 from './brewbot_8.png'
import img9 from './brewbot_9.png'
import img10 from './brewbot_10.png'
import img11 from './brewbot_11.png'
import img12 from './brewbot_12.png'
import img13 from './brewbot_13.png'
// import img14 from './brewbot_14.png'
// import img15 from './brewbot_15.png'
// import img16 from './brewbot_16.png'
import img17 from './brewbot_17.png'
import img18 from './brewbot_18.png'
import img19 from './brewbot_19.png'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';

import cover from './brewbot_cover.png';

import { NavLink } from "react-router-dom";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";


const Brewbot = () => {
  return (
    <div className="overall project-overall">
      <div className="text">
        <div className="title">
          <h1>Brewbot- Craft Beer</h1>
          <div className="description">
            <h2>illustrations for a microbrewery, inspired by iconic pop culture figures.</h2>
            <p>Illustration for packaging</p>
          </div>
        </div>
        <div className="right-text">
          <p>The brief was to packaging and labels for a Mumbai-based microbrewery, where each beer was assigned a character based on its name. With the power rangers, steampunk bots, and superhero comics as an inspiration, I started by working on the label artwork for 'Botwork Orange'.</p>
          <p>I began by character design sketches, figuring out the silhouettes of each character and making them reminiscent of the pop-culture reference they're named after (the little orange guy is Botwork Orange, and the yellow bot is called Black Mamba :D ) .</p>
          <br />
        </div>
      </div>
      <div className="project-2-cols">
        <div className="left">
          <img src={img1} alt="img" loading="eager" />
        </div>
        <div className="left">
          <img src={img8} alt="img" loading="eager" />
        </div>
      </div>

      <div className="text">
        <div className="right-text ">
          <br />
          <p>
            After that, I tried to visualise the world they live in, to figure out the context in which the little robot will be shown in.
          </p>
          <br />
        </div>
      </div>

      <div className="project-2-cols">
        <div className="left">
          <img src={img13} alt="img" loading="eager" />
        </div>
        <div className="left">
          <img src={img9} alt="img" loading="eager" />
        </div>
      </div>

      <div className="text">
        <div className="right-text ">
        <br />
          <p>
            I then proceeded to develop the central layout, and detail out the character a little more.
          </p>
          <br />
        </div>
      </div>

      <div className="project-2-cols">
        <div className="left">
          <img src={img4} alt="img" loading="eager" />
        </div>
        <div className="left">
          <img src={img11} alt="img" loading="eager" />
        </div>
        <div className="left">
          <img src={img4} alt="img" loading="eager" />
        </div>
      </div>

      <div className="text">
        <div className="left-text">

        </div>
        <div className="right-text ">
          <p>
            Worked on a few more layout options, to allow the character to look less menacing.
          </p>
          <br />
        </div>
      </div>


      <div className="project-2-cols">
        <div className="left">
          <img src={img2} alt="img" loading="eager" />
        </div>
        <div className="left">
          <img src={img6} alt="img" loading="eager" />
        </div>
        <div className="left">
          <img src={img10} alt="img" loading="eager" />
        </div>
      </div>
      <br />
      <div className="right-text">
        <p>
          The character's pose was altered to resemble the pose of the central character from A Clockwork Orange. It allowed the character to be more recognisable as Alex.<br /> <br />
          Subsequently, colours were chosen, and backgrounds were developed, to further flesh out the world of this character. <br /> <br />
          Guided by Saurabh Kanwar.
        </p>
      </div>
      <br />
      <Splide
        options={{
          gap: '1rem',
          pagination: false,
          arrows: false,
          width: '100%',
          type: 'loop',
          speed: 200,
          autoplay: true,
          interval: 2500,
          drag: true
        }}
        aria-label="My Favorite Images"
      >
        <SplideSlide>
          <div className="image3">
            <img src={img17} alt="img" loading="eager" />
          </div>
        </SplideSlide>
        <SplideSlide>
          <div className="image3">
            <img src={img18} alt="img" loading="eager" />
          </div>
        </SplideSlide>
        <SplideSlide>
          <div className="image3">
            <img src={img19} alt="img" loading="eager" />
          </div>
        </SplideSlide>
        <SplideSlide>
          <div className="image3">
            <img src={img12} alt="img" loading="eager" />
          </div>
        </SplideSlide>
      </Splide>

      <div className="navigation">
        <NavLink to="/nearly_200_days" >
          <FaArrowLeft />
        </NavLink>
        <NavLink to="/digital_elves">
          <FaArrowRight />
        </NavLink>
      </div>
    </div>
  );
};

export default Brewbot;
