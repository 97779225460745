import React, { useState, useRef ,useEffect } from 'react';
import { Link, NavLink , useLocation } from 'react-router-dom';
import './home.css';
import meraki from './Cover_Images/Case 3meraki.png';
import Alpian from './Cover_Images/Cover_Alpian.png';
import BRewbot from './Cover_Images/Cover_BRewbot.png';
import DlgitalElves from './Cover_Images/Cover_DIgitalElves.png';
import Foundry from './Cover_Images/cover_sennheiser.png';
import N200Days from './Cover_Images/Cover_N200Days.png';
import Pilolo from './Cover_Images/Cover_pilolo.png';
import WLBS from './Cover_Images/cover_wlbs.jpg';

const Home = () => {
  const [activeImage, setActiveImage] = useState(null);
  const imgRef = useRef(null);

  const location = useLocation();
  useEffect(() => {
    // import { useEffect } from "react";
    // import { NavLink , useLocation } from "react-router-dom";
    const head = document.querySelector('header');
    const footer = document.querySelector('footer');
    if (['/blog', '/about'].includes(location.pathname)) {
      document.body.style.color = 'white';
      document.body.style.backgroundColor = 'black';
      head.style.color = "white";
      head.style.backgroundColor = "black"
      footer.style.color = "white";
      footer.style.backgroundColor = "black"
    } else {
      // Reset to default styles if not on the /about route
      document.body.style.color = 'black';
      document.body.style.backgroundColor = 'white';
      head.style.color = "black";
      head.style.backgroundColor = "white"
      footer.style.color = "black";
      footer.style.backgroundColor = "white"
    }
  }, [location.pathname]);


  const handleLinkMouseOver = (image) => {
    setActiveImage(image);
    if (imgRef.current) {
      imgRef.current.style.display = 'block';
    }
  };

  const handleLinkMouseOut = () => {
    if (imgRef.current) {
      setActiveImage(null);
      imgRef.current.style.display = 'none';
    }
  };

  return (
    <div className="overall">
      <div className="home--container">
        <div className="home--contents desktop">
        <img ref={imgRef} src={activeImage} alt="" className="bg-img" style={{ display: 'none' }} />
          <div className="home--container_links">
          <div
              className="home--container_link"
            >
              <Link to="/maaher">Maaher - BRand identity</Link>
            </div>
            <div
              className="home--container_link"
              onMouseOver={() => handleLinkMouseOver(WLBS)}
              onMouseOut={handleLinkMouseOut}
            >
              <Link to="/wlbs">WLBS - Brand Identity</Link>
            </div>
            <div
              className="home--container_link"
              onMouseOver={() => handleLinkMouseOver(Pilolo)}
              onMouseOut={handleLinkMouseOut}
            >
              <Link to="/pilolo">Pilolo - Brand Identity + Packaging + Website</Link>
            </div>
            <div
              className="home--container_link"
              onMouseOver={() => handleLinkMouseOver(Foundry)}
              onMouseOut={handleLinkMouseOut}
            >
              <Link to="/foundry_berlin">Foundry, Berlin - Graduation Project - Brand Identity</Link>
            </div>
            <div
              className="home--container_link"
              onMouseOver={() => handleLinkMouseOver(Alpian)}
              onMouseOut={handleLinkMouseOut}
            >
              <Link to="/i-vest_by_alpian">i-vest by Alpian - Illustrations + Data Visualisation</Link>
            </div>
            <div
              className="home--container_link"
              onMouseOver={() => handleLinkMouseOver(meraki)}
              onMouseOut={handleLinkMouseOut}
            >
              <Link to="/meraki_clothing">Meraki Clothing - Brand Identity</Link>
            </div>
            <div
              className="home--container_link"
              onMouseOver={() => handleLinkMouseOver(DlgitalElves)}
              onMouseOut={handleLinkMouseOut}
            >
              <Link to="/digital_elves">Digital Elves - Illustration</Link>
            </div>
            <div
              className="home--container_link"
              onMouseOver={() => handleLinkMouseOver(N200Days)}
              onMouseOut={handleLinkMouseOut}
            >
              <Link to="/nearly_200_days">Nearly 200 Days - Short Film</Link>
            </div>
            <div
              className="home--container_link"
              onMouseOver={() => handleLinkMouseOver(BRewbot)}
              onMouseOut={handleLinkMouseOut}
            >
              <Link to="/brewbot_craft_brewery">Brewbot Craft Brewery - Illustration + Packaging</Link>
            </div>
          </div>
        </div>
        {/* Mobile Section Starts Here  */}
        <div className="home--container_mobile">
        <NavLink to="/maaher" className="home--container_box" style={{ background : '#341E11' }}>
            <h2> Coming Soon</h2>
            <p className="home--container-m_title">Maaher - BRand identity</p>
          </NavLink>
          <NavLink to="/wlbs" className="home--container_box">
            <p className="home--container-m_title">WLBS - Brand Identity</p>
            <img src={WLBS} alt="Winning Link" loading="eager" />
          </NavLink>
          <NavLink to="/pilolo" className="home--container_box">
            <p className="home--container-m_title">Pilolo - Brand Identity + Packaging + Website</p>
            <img src={Pilolo} alt="Pilolo" loading="eager" />
          </NavLink>
          <NavLink to="/foundry_berlin" className="home--container_box">
            <p className="home--container-m_title">Foundry, Berlin - Graduation Project - Brand Identity</p>
            <img src={Foundry} alt="Foundry" loading="eager" />
          </NavLink>
          <NavLink to="/i-vest_by_alpian" className="home--container_box">
            <p className="home--container-m_title">i-vest by Alpian - Illustrations + Data Visualisation</p>
            <img src={Alpian} alt="Alpian" loading="eager" />
          </NavLink>
          <NavLink to="/meraki_clothing" className="home--container_box">
            <p className="home--container-m_title">Meraki Clothing - Brand Identity</p>
            <img src={meraki} alt="Meraki" loading="eager" />
          </NavLink>
          <NavLink to="/digital_elves" className="home--container_box">
            <p className="home--container-m_title">Digital Elves - Illustration</p>
            <img src={DlgitalElves} alt="Digital Elves" loading="eager" />
          </NavLink>
          <NavLink to="/nearly_200_days" className="home--container_box">
            <p className="home--container-m_title">Nearly 200 Days - Short Film</p>
            <img src={N200Days} alt="Nearly 200 Days" loading="eager" />
          </NavLink>
          <NavLink to="/brewbot_craft_brewery" className="home--container_box">
            <p className="home--container-m_title">Brewbot Craft Brewery - Illustration + Packaging</p>
            <img src={BRewbot} alt="BRewbot" loading="eager" />
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Home;
