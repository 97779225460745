import { NavLink } from "react-router-dom";
import "./Footer.css"
const Footer =()=>
{
    return( <footer>
        <NavLink to="/">
          WORK
        </NavLink>
        <NavLink to="/about">
          ABOUT
        </NavLink>
        <NavLink to="/blog">
          BLOG
        </NavLink>
      </footer>)
}

export default Footer;