import React from "react";
import "../project.css";
import img1 from "./sennheiser.png";
import { NavLink } from "react-router-dom";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";


const Foundry = () => {
  return (
    <div className="overall project-overall">
      <div className="text">
        <div className="title">
          <h1>Graduation Project</h1>
          <div className="description">
            <h2>Simulated brand identity project for a digital platform for Sennheiser.</h2>
            <p>Brand identity</p>
          </div>
        </div>
        <div className="right-text">
          <p>2022-2023</p>
          <p>
          Completed as part of my graduation project, with Foundry, Berlin. <br/> <br/> More details on request.
          </p>
        </div>
        <div className="image">
          <img src={img1} alt="design" />
        </div>
      </div>
      <div className="navigation">
        <NavLink to="/pilolo">
          <FaArrowLeft />
        </NavLink>
        <NavLink to="/i-vest_by_alpian">
          <FaArrowRight />
        </NavLink>
      </div>
    </div>
  );
};

export default Foundry;
