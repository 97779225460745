import React from 'react';
import { BrowserRouter as Router, Route, Routes ,} from 'react-router-dom';
import './App.css';
import AboutPage from './Components/about/About';
import Project1 from './Components/ProjectPage1/Project1';
import Project2 from './Components/ProjectPage2/Project2';
import BlogList from './Components/BlogList/List';
import Home from './Components/Homepage/Home';
import Pilolo from './Components/Pilolo/Project';
import Ivest from './Components/Ivest/Project';
import DigitalE from './Components/DigitalE/Project';
import Brewbot from './Components/Brewbot/Project';
import Foundry from './Components/Foundry/Project';
import Wlbs from './Components/wlbs/project';

// blogs content 
import Pitbull from './Components/BlogPage/Pitbull/Blog';
import Nine36 from './Components/BlogPage/9of36/Blog';
import Garlic from './Components/BlogPage/garlic/Blog';
import Cohen from './Components/BlogPage/cohen/Blog';
import Header from './Components/header/Header';
import Footer from './Components/Footer';
import Maaher from './Components/Maaher/Project';
import Comics from './Components/BlogPage/miscellaneous-comics/Blog';
function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/blog" element={<BlogList />} />
        <Route path="/maaher" element={<Maaher />} />
        <Route path="/wlbs" element={<Wlbs />} />
        <Route path="/pilolo" element={<Pilolo/>} />
        <Route path="/foundry_berlin" element={<Foundry />} />
        <Route path="/i-vest_by_alpian" element={<Ivest />} />
        <Route path="/meraki_clothing" element={<Project2 />} /> 
        <Route path="/nearly_200_days" element={<Project1 />} />
        <Route path="/brewbot_craft_brewery" element={<Brewbot />} />
        <Route path="/digital_elves" element={<DigitalE />} /> 
        <Route path="/blog/pitbull" element={<Pitbull/>}/>
        <Route path="/blog/nine36" element={<Nine36 />}/>
        <Route path="/blog/cohen" element={<Cohen />}/>
        <Route path="/blog/garlic" element={<Garlic />}/>
        <Route path="/blog/miscellaneous-comics" element={<Comics />}/>
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
