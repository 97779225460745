import React from "react";
import "../../project.css"
import image from "./image1.png";
import { useEffect } from "react";
import { NavLink , useLocation } from "react-router-dom";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";


const Cohen = () => {
  const location = useLocation();
  useEffect(() => {
    // import { useEffect } from "react";
    // import { NavLink , useLocation } from "react-router-dom";
    const head = document.querySelector('header');
    const footer = document.querySelector('footer');
    if ((location.pathname.includes('/blog'))) {
        document.body.style.color = 'white';
        document.body.style.backgroundColor = 'black';
        head.style.color = "white";
        head.style.backgroundColor = "transparent"
        footer.style.color = "white";
        footer.style.backgroundColor = "transparent"
    } else {
        // Reset to default styles if not on the /about route
        document.body.style.color = 'black';
        document.body.style.backgroundColor = 'white';
        head.style.color = "black";
        head.style.backgroundColor = "white"
        footer.style.color = "black";
        footer.style.backgroundColor = "white"
    }
}, [location.pathname]);
  return (
    <div className="overall project-overall blog">
      <div className="blog text">
        <div className="title">
          <h1>Love & a garlic Crusher</h1>
          <div className="description">
            <h2>searching for meaning in kitchen implements, searching for a flat in berlin.</h2>
            <p>2022</p>
          </div>
        </div>
        <div className="image">
          <img src={image} alt="design" />
        </div>
        <div className="right-text">
          <p>
            After weeks of searching for a place to rent in Berlin’s notoriously difficult housing market, I got a two-month sublease for a small room in a Moabit. My flatmates were a fascinating couple. Young, creatively inclined, enterprising, and far from home, they had a lot in common–despite growing up in countries and cultures nearly antipodal to each other. They shared a world (and a kitchen) in a little flat in a comfortable but unglamorous neighbourhood of West Berlin.
            <br /> <br />
            Most days, I’d come home to a quiet apartment, with a quintessentially 2010s-hipster pink salt lamp glowing warmly in the passage. One evening, I noticed dishes in the sink, some on the little dining table, and some in the maw of what was a comically oversized dishwasher. It was well past dinnertime. Most utensils were covered in grease, crumbs, and other post-dinner debris. One thing, however, stood out: a shiny, new garlic crusher lay half-open, almost reclining by the hob, lounging in all its stainless steel glory.
            <br /> <br />
            My flatmate sleepily walked out of her room. She weakly waved a ‘hello’, filled a jug, pointed at the new implement, and said, ‘We bought that today.’ “Cool,” I replied. She didn’t seem very keen on making any more conversation and retreated into her room. I thought to myself, they bought a garlic crusher together. It was a joint purchase. I began to think about the point in a relationship when you buy a garlic crusher together.
            <br /> <br />
            Certainly not on the first date. Not necessarily on the tenth. Does it come after you move in together? You move in together. You buy plates, cutlery, pots, and pans. You may bring some along with you from your past lives. He might bring along a coffee mug with his favourite football club emblem on it; she might grab a handcrafted ceramic number. Knives and ladles will pile up over time. You may buy a blender, a toaster, an air fryer, or a kettle too. But when do you buy a garlic crusher? An object made to serve one purpose only—one that could be fulfilled adequately by any number of other tools. We had a mortar and pestle, multiple knives, and garlic puree in the fridge.
            <br /> <br />
            I tried to take my mind off the practical purpose this object served. I mustn’t fail to note that this was a marvellous little tool, two shiny halves hinged at the middle. There’s definitely a relationship metaphor in there—a corny line about masculinity and femininity that could only be rescued from becoming a crude euphemism by some clever wordsmithing, which I currently do not feel capable of. I suddenly found myself wanting a garlic crusher. Not because I was attracted to its shiny chrome exterior or its aptitude for crushing garlic.
            <br /> <br />
            Grown-ups do things like date, move in together, and buy garlic crushers. I subsequently imagined stepping into a space in time where I’m capable of holding down a relationship long enough to get to the point where you buy garlic crushers together.
            <br /> <br />
            Despite my preference for minced garlic, chopped finely and then crushed with the flat edge of a knife, that hinged implement represented something more. In its shiny chrome form, I saw shared hobbies, a shared vision of a future together, and above all, love for the sharp, earthy flavour that garlic brings to a dish.
          </p>
        </div>
      </div>
      <div className="navigation">
        <NavLink to="/blog/cohen">
          <FaArrowLeft />
        </NavLink>
        <NavLink to="/blog/miscellaneous-comics">
          <FaArrowRight />
        </NavLink>
      </div>
    </div>
  );
};

export default Cohen;
