import React, { useState, useRef ,useEffect } from 'react';
import {NavLink , useLocation } from 'react-router-dom';
import PitbullImg from '../BlogPage/Pitbull/image1.png';
import Nine36 from '../BlogPage/9of36/9.png';
import Garlic from '../BlogPage/garlic/image1.png';
import Cohen from '../BlogPage/cohen/image1.png';
import Comics from '../BlogPage/miscellaneous-comics/assets/img10.png'

import '../Homepage/home.css';
const BlogList = () => {
  const [activeImage, setActiveImage] = useState(null);
  const imgRef = useRef(null);

  const location = useLocation();

  useEffect(() => {
    // import { useEffect } from "react";
    // import { NavLink , useLocation } from "react-router-dom";
    const head = document.querySelector('header');
    const footer = document.querySelector('footer');
    if (['/blog', '/about'].includes(location.pathname)) {
      document.body.style.color = 'white';
      document.body.style.backgroundColor = 'black';
      head.style.color = "white";
      head.style.backgroundColor = "transparent"
      footer.style.color = "white";
      footer.style.backgroundColor = "transparent"
    } else {
      // Reset to default styles if not on the /about route
      document.body.style.color = 'black';
      document.body.style.backgroundColor = 'white';
      head.style.color = "black";
      head.style.backgroundColor = "white"
      footer.style.color = "black";
      footer.style.backgroundColor = "white"
    }
  }, [location.pathname]);


  const handleLinkMouseOver = (image) => {
    setActiveImage(image);
    if (imgRef.current) {
      imgRef.current.style.display = 'block';
    }
  };

  const handleLinkMouseOut = () => {
    if (imgRef.current) {
      setActiveImage(null);
      imgRef.current.style.display = 'none';
    }
  };

  return (
    <div className="overall bloglist-overall">
      <div className="home--container">
        <div className="home--contents">
        <p className='bloglist-text width-full-mobile'>(more appropriately titled‘misc.’,this is the part of my website where personal projects, poems, and experiments live.)</p>
          <div className="home--container_links width-full-mobile">
            <img ref={imgRef} src={activeImage} alt="" className="bg-img" style={{ display: 'none' }} />
            <div
              className="home--container_link bloglist-links"
              onMouseOver={() => handleLinkMouseOver(PitbullImg)}
              onMouseOut={handleLinkMouseOut}
            >
              <NavLink to="/blog/pitbull">Pitbull as an absurd hero</ NavLink>
            </div>
            <div
              className="home--container_link bloglist-links"
              onMouseOver={() => handleLinkMouseOver(Nine36)}
              onMouseOut={handleLinkMouseOut}
            >
              <NavLink to="/blog/nine36">9 out of 36 days of type </NavLink>
            </div>
            <div
              className="home--container_link bloglist-links"
              onMouseOver={() => handleLinkMouseOver(Cohen)}
              onMouseOut={handleLinkMouseOut}
            >
              <NavLink to="/blog/cohen">To L.Cohen</NavLink>
            </div>
            <div
              className="home--container_link bloglist-links"
              onMouseOver={() => handleLinkMouseOver(Garlic)}
              onMouseOut={handleLinkMouseOut}
            >
              <NavLink to="/blog/garlic">Love & a garlic crusher </NavLink>
            </div>
            <div
              className="home--container_link bloglist-links"
              onMouseOver={() => handleLinkMouseOver(Comics)}
              onMouseOut={handleLinkMouseOut}
            >
              <NavLink to="/blog/miscellaneous-comics">Miscellaneous Comics </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogList;
