import "../../project.css"
import image from "./image1.png";
import { useEffect } from "react";
import { NavLink , useLocation } from "react-router-dom";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";


const Cohen = () => {

  const location = useLocation();
  useEffect(() => {
    // import { useEffect } from "react";
    // import { NavLink , useLocation } from "react-router-dom";
    const head = document.querySelector('header');
    const footer = document.querySelector('footer');
    if ((location.pathname.includes('/blog'))) {
        document.body.style.color = 'white';
        document.body.style.backgroundColor = 'black';
        head.style.color = "white";
        head.style.backgroundColor = "transparent"
        footer.style.color = "white";
        footer.style.backgroundColor = "transparent"
    } else {
        // Reset to default styles if not on the /about route
        document.body.style.color = 'black';
        document.body.style.backgroundColor = 'white';
        head.style.color = "black";
        head.style.backgroundColor = "white"
        footer.style.color = "black";
        footer.style.backgroundColor = "white"
    }
}, [location.pathname]);

  return (
    <div className="overall project-overall blog">
      <div className="blog text">
        <div className="title flex-column text-center">
          <h1 className="width-full">To L.Cohen</h1>
          <div className="description width-full">
            <p className="width-full text-center">2022</p>
          </div>
        </div>
        <div className="image">
          <img src={image} alt="design" />
        </div>
        <div className="right-text">
          <p>
            You told me not to<br />
            lament casually,<br />
            So I never will.<br />
            If I must lament,<br />
            I’ll tear open a lung,<br />
            And hang it to dry.<br />
            And on its dark inside,<br />
            I’ll write.
            <br /> <br />
            I’ll write what was said  <br />
            And write what I heard.  <br />
            I’ll write like a saint  <br />
            Or a bullied young nerd.  <br />
            I’ll write like my ex,  <br />
            Pathetic and weak. <br />
            Or I’ll write like the wind, <br />
            And litter the creek.
            <br /> <br />
            I’ll write like my teacher, <br />
            neat, and in rows. <br />
            I’ll write like myself, <br />
            In monotonous prose. <br />
            I’ll write like a loser, <br />
            That, I can do.  <br />
            But I’ll never be able <br />
            To write like you.
            <br /> <br />
            Sincerely,

          </p>
        </div>
      </div>
      <div className="navigation">
        <NavLink to="/blog/nine36">
          <FaArrowLeft />
        </NavLink>
        <NavLink to="/blog/garlic">
          <FaArrowRight />
        </NavLink>
      </div>
    </div>
  );
};

export default Cohen;
