import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import profileImage from './Shriya-2.png';
import { Link } from 'react-router-dom';
import './aboutpage.css';

const AboutPage = () => {
  const location = useLocation();

  useEffect(() => {
    // import { useEffect } from "react";
    // import { NavLink , useLocation } from "react-router-dom";
    const head = document.querySelector('header');
    const footer = document.querySelector('footer');
    if ((location.pathname.includes('/about'))) {
      document.body.style.color = 'white';
      document.body.style.backgroundColor = 'black';
      head.style.color = "white";
      head.style.backgroundColor = "transparent"
      footer.style.color = "white";
      footer.style.backgroundColor = "transparent"
    } else {
      // Reset to default styles if not on the /about route
      document.body.style.color = 'black';
      document.body.style.backgroundColor = 'white';
      head.style.color = "black";
      head.style.backgroundColor = "white"
      footer.style.color = "black";
      footer.style.backgroundColor = "white"
    }
  }, [location.pathname]);

  return (
    <div className='overall'>
      <div className="content-container">
        <div className="about-content">
          <div className="about-image">
            <img src={profileImage} alt="Shriya Sista" />
          </div>
          <div className="about-text">
            <p>I am a communication designer who believes that brands aren’t simply a form of superfluous augmentation to our digital and physical worlds, but an important part of our daily lives, visual culture, and collective memory. I have worked on and supported projects for brands across various sectors, countries and languages. </p>
            <br />
            <p>As a graduate of the National Institute of Design, India, my work is influenced by its multidisciplinary, multi-cultural, and collaborative learning environment. I speak English, Hindi, Telugu, und ein bisschen Deutsch. Outside of work, I find joy in music from every corner of the world, cooking, windsurfing, and films</p>
            <br />
            <p>Talk about work/your favourite band/food/anything else - </p>
            <p><a className='link' href='mailto:sistashriya@gmail.com'>sistashriya@gmail.com</a></p><br />
            <p><Link className='link' to="https://www.linkedin.com/in/shriya-sista-535809140/" target="_blank">LinkedIn</Link></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
