import React, { useState } from 'react';
import './project1.css';
import image from './image 5.png';
import image2 from './image 7.png';
import image3 from './image 8.png';
import image4 from './image 9.png';
import { NavLink } from "react-router-dom";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
const Project1 = () => {

  return (
    <div className='overall project-overall'>
      <div className="text">
        <div className="title">
          <h1>Nearly 200 days </h1>
          <div className="description">
            <h2>an Experimental Short film shot over the course of an academic module            </h2>
            <p>Experimental Short Film + Classroom Project</p>
          </div>
        </div>
        <div className="image">
          <img src={image} alt="design" />
        </div>
        <div className='right-text'>
          <p>
            This film was written, shot, and edited during an academic module in college. It was shot at home, after months of lockdown and isolation due to the Novel Coronavirus. There was no budget, and the entire project—concept to final export (4m 8s)—took three weeks to complete.<br /> <br />Guided by Savyasachi Anju Prabir.
          </p>
        </div>
        <div className="image">
          <img src={image3} alt="design" />
        </div>
        <div className="right-text">
          <p>
            Synopsis:<br /><br />
            Time, memories, and coffee.<br /><br />
            Nearly 200 Days is an experimental film about the experience of time during a lockdown. It is a first-person account of how time seems to dilate and contract when one is stuck in the same routine for a prolonged period. I chose to explore the perceived distortion in time during lockdown and the reasons this distortion is perceived. I chose to look into the effect of stress, social fulfillment, and emotional states on time, among other factors.<br /><br />
            "Subjective time is not isomorphic to physical time." - R. Efron
          </p>
        </div>
      </div>
      <div className="image2">
        <div className="video-container" style={{ position: 'relative', paddingTop: '56.25%' /* 16:9 Aspect Ratio */ }}>
          <iframe src="https://player.vimeo.com/video/999416843?h=b006767bf4" style={{ position: 'absolute', top: 0, left: 0 , width:'100%' , height: '100%' }} frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
      <br />
      <div className='right-text'>
        <p>
          Film Festival Selections:<br /><br />
          <ul>
            <li>
              Olympia International Youth Film Festival's Camera Zizanio, 2020
            </li>
            <li>
              International Science Film Festival of India, 2020
            </li>
            <li>
              Veracruz World Film Festival, 2020
            </li>
            <li>
              Lift-Off Global Network- Lift-Off Online Sessions, January 2021
            </li>
            <li>
              PSBT Challenge Showcase, 2021
            </li>
          </ul>

          Awards/Nominations:<br /><br />
          <ul>
            <li>
              Jury Award - Public Service Broadcasting Trust, India - PSBT Challenge, 2020
            </li>
            <li>
              Nominee - International Science Film Festival of India, 2020
            </li>
          </ul>
          <br />
        </p>
      </div>
      <div className="image3">
        <img src={image4} alt="design" />
      </div>
      <div className="navigation">
        <NavLink to="/meraki_clothing">
          <FaArrowLeft />
        </NavLink>
        <NavLink to="/brewbot_craft_brewery">
          <FaArrowRight />
        </NavLink>
      </div>
    </div>
  );
};

export default Project1;
